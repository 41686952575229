.flip-main {
  .flip-front {
    background-color: $color-dark2;
    .card-img{
      width: 100%;
      height:200px;
      .MuiAvatar-img {
          object-fit: contain !important;
      }
  }
  }
  .flip-front-footer {
    width: 100%;
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
    text-transform: uppercase;
  }
  .flip-back{
      background-color: $color-warning;
  }
  .flippy-icons{
    img{
      width: 60px;
    }
    .flippy-buttons{
    position: absolute;
    width: 100%;
    bottom: 10px;
    }
  }
}
.transform-icon{
  bottom:0;
  width: 100%;
  text-align: center;
}
.serum-img-modal{
  width: 150px !important;
  height: 150px !important;
}
.flip-front-price{
  position: absolute;
  bottom: 10px;
  right: 15px;
  width: 100%;
}