/*---------------------
		Footer Styles
-----------------------*/


footer {
   background-color: $color-dark;
   min-height: 50px;
   position: relative;
   margin-top: -50px;
   /* negative value of footer height */
   min-height: 50px;
   clear: both;
   *{
   color: $color-light;
   }
  }

  footer {
    position: relative;
    margin-top: -70px;
    /* negative value of footer height */
    min-height: 70px;
    clear: both;
  }
  
  body:before {
    content: "";
    height: 100%;
    float: left;
    width: 0;
    /* thank you Erik J - negate effect of float*/
  }
  ul {
    list-style-type: none;
  }
  
  li {
    float: left;
    margin-left: 0.5rem;
  }
  