.page-title { 
    text-align: center;
    color: $color-light;
    text-transform: uppercase;
    padding: 1px;
    font-family: 'Raleway', cursive;
    font-weight: 100;
    position: relative;
   h1 {
    display: block;
    padding: 20px;
    text-decoration: none;
    letter-spacing: 1rem;
    color: $color-light;
  }
}