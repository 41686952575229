.count-down-container {
  font-size: 20px;
  color: rgb(41, 40, 40);
  .month,
  .hour,
  .day,
  .min,
  .sec {
    font-size: 26px;
    font-weight: bold;
    color: $color-warning-light;
  }
}
.simply-countdown {
  /* The countdown */
  margin-top: 1em;
  margin-bottom: 1em;
}

.simply-countdown > .simply-section {
  /* coutndown blocks */
  display: inline-block;
  width: 120px;
  height: 120px;
  background: $color-warning-light;
  margin: 0 5px;
  position: relative;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.simply-countdown > .simply-section > div {
  /* countdown block inner div */
  display: table-cell;
  vertical-align: middle;
  height: 115px;
  width: 120px;
}

.simply-countdown > .simply-section .simply-amount,
.simply-countdown > .simply-section .simply-word {
  display: block;
  color: #242735;
  /* amounts and words */
}

.simply-countdown > .simply-section .simply-amount {
  font-size: 40px;
  /* amounts */
}

.simply-countdown > .simply-section .simply-word {
  color: #242735;
  text-transform: uppercase;
  font-size: 14px;
  /* words */
}