body{
    background-color:$color-dark2;
}
.no-data{
    color:$color-light;
}
.no-auth-container{
    *{
        color: $color-light;
    }
}