.h-min-100vh{
    min-height: 100vh;
}
.border-radius-0{
    border-radius: 0px !important;
}
.cursor-pointer{
    cursor: pointer;
}
.width-30{
    width: 30px !important;
}
.width-60{
    width: 60px !important;
}
.bg-dark2{
    background-color: $color-dark2 !important;
}
.bg-dark{
    background-color: $color-dark !important;
}
.border-radius-50{
    border-radius: 50%;
}