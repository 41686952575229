/*===== Colors =====//*/
$theme-color: #001232;
$body-color: #666666;
$heading-color: #333333;

/*===== Font Family =====//*/
$body-font: 'Source Sans Pro', sans-serif;
$heading-font: 'Oswald', sans-serif;

/*===== Line Height =====//*/
$body-line-height: 24px;

/*===== Radius  =====//*/
$theme-radius: 3px;


/*==== Font size =====//*/
$body-font-size: 16px;

/*===== Border color ======//*/
$border-color-light: #eeeeee;
$border-color-medium: #aaaaaa;
$border-color-dark: #333333;

/*===== Alerts color  =====//*/
$color-primary: #337ab7;
$color-success: #5cb85c;
$color-info: #5bc0de;
$color-warning: #f0ad4e;
$color-danger: #d9534f;
$color-dark: rgb(33, 37, 41);
$color-dark2: #242735;
$color-light: rgba(255, 255, 255, 0.55);
$color-warning-light: #ffc107;


/* Layouts*/
$sp-layout : 'only screen and (min-width: 1920px)';
$xx-layout : 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$xlg-layout : 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout : 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';
$sm-layout:'only screen and (max-width: 767px)';
$xs-layout: 'only screen and (max-width: 575px)';
$xxs-layout: 'only screen and (max-width: 420px)';


