.form-control,
.form-select {
  height: 44px;
  background-color: $color-dark2;
  color: #666;
}
.fadeOut {
  opacity: 0;
  width: 0;
  height: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
.fadeIn {
  opacity: 1;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}
.radio-group {
  opacity: 0;
  transition: opacity 0.5s linear;
  label{
    padding: 0.25rem 1.75rem !important;
  }
}
.showopacity {
  opacity: 1;
}
.radio-group,
.checkbox-group {
  label {
    background-color: $color-dark2 !important;
    border-color: transparent !important;
   // padding: 0.375rem 1.75rem;
    // height: 44px;
    box-shadow: none !important;
    outline: none !important;
  }
  .btn-primary {
    &:focus,
    &:active {
      box-shadow: none !important;
      background-color: transparent !important;
    }
    &:hover {
      background-color: transparent !important;
    }
  }
  .btn-check {
    &:focus,
    &:active {
      box-shadow: none !important;
      background-color: $color-warning !important;
    }
    &:checked + .btn-primary {
      background-color: $color-warning !important;
      box-shadow: none !important;
    }
  }
}
.checkbox-group {
  .btn-check {
    &:focus,
    &:active {
      box-shadow: none !important;
      background-color: $color-warning !important;
    }
    &:checked + .btn-primary {
      background-color: transparent !important;
      box-shadow: none !important;
    }
  }
}

.radio-group .btn-check:checked + .btn-primary {
  border: 1px solid $color-warning;
  background-color: transparent !important;
  border-color: $color-warning !important;
}
.serum-select {
  width: 100%;
  background-color: #242735;

  & > div {
    background-color: #242735;
  }
  *{
    color: #fff;
  }
  [class*="singleValue"]{
    color:#fff;
  }
  [class*="multiValue"]{
    *{
    background-color: #f0ad4e;
    color: #fff;
    }
  }
}

@media #{$md-layout} {
  .radio-group,
  .checkbox-group {
    label {
      // padding: 0.375rem 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}
@media #{$sm-layout} {
  .radio-group,
  .checkbox-group {
    label {
      // padding: 0.375rem 1.5rem;
      margin-bottom: 0.5rem;
    }
  }
}
@media #{$xs-layout} {
  .radio-group,
  .checkbox-group {
    label {
      // padding: 0.375rem .5rem;
      margin-bottom: 0.5rem;
    }
  }
}
