
.header--absolute {


    &.stick {
        position: fixed;
        top: 0;
        animation: 700ms ease-in-out;
        animation-name: fadeInDown;
       width: 100%;
       z-index: 2;
    }
}

.logo {
    padding: 0;

    text-align: left;
}


@keyframes fadeInDown {
    0% {
        transform: translate3d(0, -100px, 0);

        opacity: 0;
    }
    to {
        transform: none;

        opacity: 1;
    }
}
.robe-metamask-logo{
    width: 30px;
    cursor: pointer;
}
.p-logo{
    width: 60px;
    margin-bottom: 4px;
}
.h2-logo{
    width: 130px;
    margin-bottom: 8px;
}
.h3-logo{
    width: 90px;
    margin-bottom: 4px;
}
.h4-logo{
    width: 90px;
    margin-bottom: 4px;
}
.modal-video-movie-wrap{
    width: 1000px;
}