.horizontal-tabs {
  border-bottom-color: $color-warning;
  .nav-link {
    color: $color-warning;
    cursor: pointer;
    &.active {
      background-color: $color-warning;
      color: #fff;
      border-color: $color-warning;
    }
    &:hover{
      border-color:$color-dark2;
      border-bottom-color:$color-warning;
    }
  }
}
