.side-tabs-left{
    border-right: 1px solid;
.side-tabs {
  .nav-link {
    color: #fff;
    cursor: pointer;
    &.active {
      background-color: transparent;
      color: $color-warning;
      border:1px solid;
    }
  }
}
}
