.nft-card{
    background-color:#282b39;
    padding:.5rem;
    *{
    color: $color-light;
}
position: relative;

    .nft-card-icons{
        position: absolute;
        bottom: 5px;
        width: 100%;
        left:1rem;
        img{
        width: 30px;
        cursor: pointer;
        }
    }
    .card-img{
        width: 100%;
        height:400px;
        .MuiAvatar-img {
            object-fit: contain !important;
        }
    }
}
.image-container-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-dark2;
  }